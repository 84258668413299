<div class="aw-box" *ngIf="totalItems > 0">
    <div class="fetching-data-overlay" *ngIf="loadingData"></div>
    <section [class]="sidescrollable ? 'table-container' : ''">
        <section [class]="sidescrollable ? 'side-scroll-container' : ''"
            [ngStyle]="{'min-width': minTableWidthPx + 'px'}">
            <ng-container>
                <div [className]="hasExpandableOrClickableRows ? 'aw-table-expandable-header' : 'aw-table-header'">
                    <row [style]="sidescrollable ? 'width: 100%;' : ''">
                        <cell *ngFor="let header of tableHeaders; let i = index"
                            [className]="loadingData ? 'loading '+findAlignment(header) : findAlignment(header)"
                            [style]="sidescrollable ?
                                    ('min-width: ' + header.width + ';' +'text-align: ' + header.alignment) :
                                    ('width: ' + header.width + ';' +'text-align: ' + header.alignment)">

                            <!--This part is only applicable for Billing & Discount table, should maybe be refactored-->
                            <ng-container>
                                <div style="width: 100%; justify-content: end; padding-top: 1px;"
                                    *ngIf="(showCheckBoxPosition === 'end' && i === tableHeaders.length - 1) || (showCheckBoxPosition === 'start' && i === 0)">
                                    <ng-content select="[header-checkbox]"></ng-content>
                                </div>
                            </ng-container>

                            <p [ngStyle]="{
                                'position': 'relative',
                                'min-width': 'fit-content',
                                'width': '100%',
                                'cursor': header.sortKey !== '' ? 'pointer' : ''
                                }" (click)="header.sortKey !== '' ? emitSortBy(header.sortKey) : null"
                                *ngIf="!(showCheckBoxPosition === 'end' && i === tableHeaders.length - 1)">
                                {{header.title}}
                                <img *ngIf="(sortSelected === header.sortKey) && header.title && header.sortKey !== ''"
                                    class="arrow"
                                    src='../../../assets/icons/{{ sortAscending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                                    alt="sort-arrow">
                            </p>
                        </cell>
                    </row>
                </div>
                <table [className]="hasExpandableOrClickableRows ? 'aw-table-expandable' : 'aw-table'">
                    <ng-content select="[table-body]"></ng-content>
                </table>
            </ng-container>
        </section>
    </section>
    <mat-paginator id="matpage" [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageNumber" (page)="handlePage($event)" showFirstLastButtons class="pagination"></mat-paginator>
</div>

<div class="empty-row" *ngIf="totalItems === 0 && !loadingData">
    <div>{{emptytext}}</div>
</div>