import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableHeaderOptions } from '../../../../../shared_models/aw-components/tableHeaderOptions';
import { Sort } from '../../../../../shared_models/search-params/FilterSortParams';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { NgIf, NgStyle, NgFor } from '@angular/common';

@Component({
    selector: 'app-aw-table',
    templateUrl: './aw-table.component.html',
    styleUrls: ['./aw-table.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, NgFor, MatPaginator]
})
export class AwTableComponent implements OnInit {
    @Input() tableHeaders: TableHeaderOptions[] = [];
    @Input() loadingData = false;
    @Input() initalSort: Sort;
    @Input() emptytext = 'This table is empty';
    @Input() hasExpandableOrClickableRows = false;
    @Input() sidescrollable = false;
    @Input() minTableWidthPx = 1090; //Default fits page width minus padding

    @Output() sortBy: EventEmitter<Sort> = new EventEmitter<Sort>();

    //Only applicable for the billing table - should maybe be refactored in the future..
    @Input() showCheckBoxPosition = '';

    sortSelected = '';
    sortAscending = true;
    lastSortKey = '';
    sortDirections: Record<string, 'asc' | 'desc'> = {};
    // Necessary inputs for FE pagination
    @Input() pageNumber = 0;
    @Input() totalItems = 0;
    @Output() handlePageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Input() pageSizeOptions: number[] = [15, 50, 100];
    @Input() pageSize: number = this.pageSizeOptions[0];

    ngOnInit() {
        this.pageSize = this.pageSizeOptions[0];
        this.sortSelected = this.initalSort.key;
        this.sortAscending = this.initalSort.order === 'asc';
        this.lastSortKey = this.initalSort.key;
        for (const header of this.tableHeaders) {
            if (header.sortDirection) {
                this.sortDirections[header.sortKey] = header.sortDirection;
            }
        }
    }

    findSortDirection(sortKey: string): 'asc' | 'desc' {
        return this.tableHeaders.find(header => header.sortKey === sortKey).sortDirection;
    }

    emitSortBy(sortKey: string): void {
        if (!this.loadingData) {
            if (this.findSortDirection(sortKey) !== undefined) {
                // key has own sort configurations
                // Apply them, if not already applied and reverse them if already applied

                if (this.lastSortKey !== sortKey) {
                    this.lastSortKey = sortKey;
                    this.sortSelected = sortKey;
                    this.sortAscending = this.findSortDirection(sortKey) === 'asc';

                    this.sortBy.emit({ order: this.findSortDirection(sortKey), key: sortKey });
                } else {
                    const declaredDirection = this.findSortDirection(sortKey);
                    const lastKnownDirection = this.sortDirections[sortKey];

                    if (declaredDirection !== lastKnownDirection) {
                        this.sortDirections[sortKey] = declaredDirection === 'asc' ? 'asc' : 'desc';
                    } else {
                        this.sortDirections[sortKey] = declaredDirection === 'asc' ? 'desc' : 'asc';
                    }

                    this.sortSelected = sortKey;
                    this.sortAscending = this.sortDirections[sortKey] === 'asc';

                    this.sortBy.emit({ order: this.sortDirections[sortKey], key: sortKey });
                }
            } else {
                if (this.sortSelected === sortKey) {
                    this.sortAscending = !this.sortAscending;
                } else {
                    this.sortAscending = true;
                }
                this.sortSelected = sortKey;
                const sortDirection: 'asc' | 'desc' = this.sortAscending ? 'asc' : 'desc';

                const sort: Sort = { order: sortDirection, key: sortKey };

                this.sortBy.emit(sort);
            }
        }
    }

    findAlignment(header: TableHeaderOptions): string {
        switch (header.alignment) {
            case 'left':
                return 'set-align-left';
            case 'center':
                return 'set-align-center';
            case 'right':
                return 'set-align-right';
            default:
                return 'set-align-left';
        }
    }

    handlePage(e: PageEvent): void {
        this.handlePageChange.emit(e);
    }
}
